import styled from "@emotion/styled";
import Section, { Para } from "./elements/Section";
import PropTypes from "prop-types";
import {
	container,
  grid,
	gridc,
	screen,
	spacingRestricted
} from "../styles/mixins";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import color from "../styles/colors";
import FluidImagerX from "./elements/FluidImagerX";

const eggplant = color("purple");

const SectionEl = styled(Section)`
	padding-bottom: ${props => props.noPadding ? "0px !important" : "80px !important" };
	padding-top: 0 !important;
  background-color: ${eggplant};

	@media ${screen("md")} {
		padding-bottom: ${props => props.noPadding ? "0px !important" : "100px !important" };
		padding-top: 0 !important;
	}
`;

const ContainerEl = styled.div`
	${container()}
`;

const Container = styled.div`
	display: flex;
	width: 100%;
	position: relative;
	background-color: ${props => color(props.bgColor)};
	flex-direction: column;

	@media ${screen("ml")} {
		flex-direction: row;
		max-height: 620px;
	}

	@media ${screen("lg")} {
		max-height: 720px;
	}
`;

const ImageWrapper = styled.div`
  flex: 1;
	position: relative;
	overflow: hidden;
	flex-basis: 100%;
	aspect-ratio: 1;

	@media ${screen("ml")} {
		max-width: 620px;
	}

	@media ${screen("lg")} {
		max-width: 720px;
	}
`;

const ContainerWidth = styled.div`
	${gridc(3, 44)}
  background-color: ${color("off-purple")};
  border-radius: 20px;
	overflow: hidden;

	@media ${screen("md")} {
		${gridc(3, 44)}
		border-radius: 15px 15px 150px 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 100%;
	color: ${color("white")};
	padding: 40px 30px;

	@media ${screen("ml")} {
		flex: 1;
		padding: 80px 50px;
	}

  @media ${screen("lg")} {
    padding: 120px 85px;
  }
`;

const Text = styled.div`

	@media ${screen("md")} {
		display: block;
		margin-top: 0;
	}
`;

const Heading = styled.h2`
	font-size: 40px;
	line-height: 1.1;
  letter-spacing: -0.01em;
	font-weight: 500;
  margin-bottom: 15px;

	em {
		color: ${color("orange")};
		font-style: normal;
	}

	@media ${screen("ml")} {
    font-size: 45px;
    margin-bottom: 30px;
	}
`;

const Highlight = styled.span`
  display: block;
  color: ${color("beige")};
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 14px;

  @media ${screen("ml")} {
    margin-bottom: 8px;
  }
`;

const P = styled(Para)`
	font-size: 20px;
	line-height: 1.3;

	@media ${screen("lg")} {
		font-size: 18px;
	}
`;

const Cta = styled.div`
	margin-top: 40px;

	@media ${screen("ml")} {
    margin-top: 50px;
	}
`;

const CtaButton = styled(Button)`
	width: 100%;

	${button};
	${gridc(1, 42)}

	@media ${screen("md")} {
		transform: none;
		width: auto;
		max-width: none;
		margin-right: 0.5em;
		margin-bottom: 0.5em;
	}
`;

const Image = styled(FluidImagerX)`
  width: 100%;
  height: 100%;
`;

const CtaImageSection = ({
	colorTheme = "dark",
	data,
	noPadding = false
}) => {

	return data ? (
		<SectionEl noPadding={noPadding}>
			<ContainerEl>
				<ContainerWidth>
					<Container bgColor={data.theme}>
            <ImageWrapper>
              <Image
                image={data?.image[0]}
                sizes="(min-width: 2200px) 1520px, (min-width: 720px) 50vw, 100vw"
                loading="eager"
                alt={
                  data.image[0]
                    ? data.image[0].title
                    : null
                }
                gatsbyImage={false}
                />
            </ImageWrapper>
						<Content>
							<Text colorTheme={colorTheme}>
								{data.heading && <Heading><div dangerouslySetInnerHTML={{ __html: data.heading }} /></Heading>}
                {data.highlight && <Highlight>{data.highlight}</Highlight>}
								{data.text && <P as="div" dangerouslySetInnerHTML={{ __html: data.text }} />}
							</Text>
								{data?.button && (
							    <Cta>
                    <CtaButton
                      url={data.button.url}
                      target={data.button.target}
                      linkType={data.button.type}
                      element={data.button?.element}
                      external={data.button.type !== "entry"}
                      data-event-name="select_cta"
                      data-content-type="Go to page"
                      data-content-destination={data.button.url}
                      data-content-text={data.button.text}>
                      {data.button.text}
                    </CtaButton>
				    			</Cta>
								)}
						</Content>
					</Container>
				</ContainerWidth>
			</ContainerEl>
		</SectionEl>
	) : null;
};

CtaImageSection.propTypes = {
	colorTheme: PropTypes.string,
};

CtaImageSection.defaultProps = {
	colorTheme: "dark",
};

export default CtaImageSection;
